import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "moment/locale/es";
import moment from "moment";
import numeral from 'numeral';
import history from './utils/history';



moment.locale("es");


numeral.register('locale', 'es', {
	delimiters: {
			thousands: '.',
			decimal: ','
	},
	abbreviations: {
			thousand: 'k',
			million: 'm',
			billion: 'b',
			trillion: 't'
	},
	ordinalIgnore: function (number) {
			return number === 1 ? 'er' : 'ème';
	},
	currency: {
			symbol: '€'
	}
});

numeral.locale('es');
console.log(window.location.origin)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
