import React, { Component } from "react";
import { Route, NavLink, Switch } from "react-router-dom";
import Home from '../Pages/Home';
import { Layout, Menu, Icon, Avatar, Dropdown } from 'antd';
import Login from "../Pages/Login";


const { Header, Sider } = Layout;

class NavigationExternal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
    };
  }


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }


  render() {
    const content = (<Layout>
      <Layout>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/login" exact component={Login} />
        </Switch>
      </Layout>
    </Layout>);

    return content;
  }
}


export default NavigationExternal;