import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyCFCPzNIjPgCJ7bCE_yJHhiUVGDo5SkCnY",
  authDomain: "altair-hosting.firebaseapp.com",
  databaseURL: "https://altair-hosting.firebaseio.com",
  projectId: "altair-hosting",
  storageBucket: "altair-hosting.appspot.com",
  messagingSenderId: "232213417286",
  appId: "1:232213417286:web:0815a0b3f2bffbd167e947",
  measurementId: "G-J2TLG3EFMN"
};

firebase.initializeApp(firebaseConfig);

const ownFirebase = {
	firebase,
	firestore: firebase.firestore,
	db: firebase.firestore(),
	auth: {
		getUser: () => {
			const user = firebase.auth().currentUser;
			return user;
		},
		login: async ({user, password}) => {
			const auth = await firebase.auth().signInWithEmailAndPassword(user, password);
			console.log('auto', auth, ownFirebase.auth.getUser())
			return auth;
		},
		onStatusChange: (checkState) => {
			firebase.auth().onAuthStateChanged((user) => {
				checkState(user);
			});
		}
	}
}


export default ownFirebase;