import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { Col, Layout, Row, Breadcrumb, Table, Switch, Card, Form, Input, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret, faShield } from '@fortawesome/pro-duotone-svg-icons';
import firebase from '../utils/firebase';



const { Content } = Layout;


class Login extends Component {
  async onFinish(values) {
    console.log('Success:', values, firebase.auth.getUser());
    await firebase.auth.login(values);
    console.log(firebase.auth.getUser())
    if(firebase.auth.getUser()) {
      console.log('estoy aquiii')
      this.props.history.push('/');
    }
  }


  onFinishFailed(errorInfo) {
    console.log('Failed:', errorInfo);
  }


  render() {
    return (
      <Layout className="page-mortgages">
        <Content className="sp-content">
          <Row>
            <Col offset={8} span={8}>
              <Card title="Auth" bordered={true}>
                <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} name="auth" onFinish={(values) => this.onFinish(values)} onFinishFailed={()  => this.onFinishFailed()}>
                  <Form.Item
                    label="User"
                    name="user"
                    rules={[{ required: true, type: 'email', message: 'Please input your user!' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                  >
                    <Input.Password />
                  </Form.Item>
                  <Form.Item >
                    <Button type="primary" htmlType="submit">Login</Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>);
  }
}

export default withRouter(Login);
