import React, { Component } from "react";
import { Route, NavLink, Switch } from "react-router-dom";
import Home from '../Pages/Home';
import { Layout, Menu, Icon, Avatar, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAstronaut } from '@fortawesome/pro-duotone-svg-icons';
import firebase from '../utils/firebase';


const { Header, Sider } = Layout;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
			collapsed: false,
		};
  }


  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }


  render() {
    const { location } = this.props;

    const menu = (
      <Menu>
        <Menu.Item key="0">
          <NavLink to='/logout'>
            <Icon type="logout" />
            <span>&nbsp; Cerrar sesión</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    );

    console.log(firebase.auth.getUser())

    const content = (<Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={this.state.collapsed}
      >
        <div className="logo">
          <img src='https://altairstudios-hosting-backup.ams3.digitaloceanspaces.com/web/logo-white-160x160.png' alt="logo" />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['/']}
          selectedKeys={[location.pathname]}>
          <Menu.Item key="/">
            <NavLink to='/'>
              <Icon type="home" />
              <span>Home</span>
            </NavLink>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', padding: 0 }}>
          <Icon
            className="trigger"
            type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
            onClick={this.toggle}
          />
          <div style={{float: 'right'}}>
            <Avatar style={{background: 'red'}} size="large">
              <FontAwesomeIcon icon={faUserAstronaut} />
            </Avatar>
            &nbsp;
            <Dropdown overlay={menu}>
              <a className="ant-dropdown-link">
                {firebase.auth.getUser().email} <Icon type="down" />
              </a>
            </Dropdown>
            &nbsp;
          </div>
        </Header>
        <Switch>
          <Route path="/" exact component={Home} />
        </Switch>
      </Layout>
    </Layout>);

    return content;
  }
}


export default Navigation;