import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";
import { Icon, Layout, Row, Breadcrumb, Table, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserSecret, faShield } from '@fortawesome/pro-duotone-svg-icons';
import firebase from '../utils/firebase';



const { Content } = Layout;


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }


  componentDidMount() {
    const query = firebase.db.collection('domains').get().then((snapshot) => {
      const data = [];
      snapshot.forEach(doc => {
        console.log(doc.id, '=>', doc.data());
        data.push({ id: doc.id, ...doc.data() });
      });

      this.setState({ data });
    });

    /*query.onSnapshot(function(snapshot) {
      snapshot.docChanges().forEach(function(change) {
        console.log('change', change)
        if (change.type === 'removed') {
          //deleteMessage(change.doc.id);
        } else {
          var message = change.doc.data();
          displayMessage(change.doc.id, message.timestamp, message.name,
                         message.text, message.profilePicUrl, message.imageUrl);
        }
      });
    });*/
  }


  render() {
    const dataSource = [{
      id: 1,
      domain: 'www.altairstudios.es',
      ssl: true,
      server: '195.123.123.123'
    }, {
      id: 1,
      domain: 'www.keiva.es',
      ssl: false,
      server: '195.123.123.123'
    }, {
      id: 1,
      domain: 'www.altairstudios.es',
      ssl: true,
      server: '195.123.123.123'
    }, {
      id: 1,
      domain: 'www.keiva.es',
      ssl: false,
      server: '195.123.123.123'
    }];


    const columns = [
      {
        title: 'id',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Domain',
        dataIndex: 'domain',
        key: 'domain',
      },
      {
        title: 'server',
        dataIndex: 'server',
        key: 'server',
      },
      {
        title: 'ssl',
        dataIndex: 'ssl',
        key: 'ssl',
        render: () => (<Switch
          checkedChildren={<FontAwesomeIcon icon={faShield} />}
          unCheckedChildren={<FontAwesomeIcon icon={faUserSecret} />}
        />)
      },
      {
        title: 'actions',
        dataIndex: 'actions',
        key: 'actions',
        render: () => (<div><a>Delete</a> <a>Add exception</a></div>)
      }
    ];


    


    return (
      <Layout className="page-mortgages">
        <Content className="sp-content">
        <Breadcrumb>
          <Breadcrumb.Item>
            <NavLink to="/">
              <Icon type="home" />
              &nbsp;
              <span>Home</span>
            </NavLink>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Content className="sp-content">
          <Row gutter={16}>
            <Table dataSource={this.state.data} columns={columns} bordered expandedRowRender={record => <p style={{ margin: 0, width: '100%' }}>{record.description}</p>}></Table>
          </Row>
        </Content>
      </Content>
    </Layout>);
  }
}

export default withRouter(Home);
