import React, { Component } from "react";
import "./App.less";
import { Router, Route, Redirect } from "react-router-dom";
import Navigation from './Components/Navigation';
import { Layout, Spin } from 'antd';
import history from './utils/history';
import firebase from './utils/firebase';
import NavigationExternal from "./Components/NavigationExternal";



class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, logged: false };
  }

  async componentDidMount() {
    /*if (auth.isAuthenticated()) {
      const extraMillis = 10000;
      this.scheduleSessionRefresh(auth.expiresInMillis() - extraMillis);

      this.setState({ loading: false });
    } else {
      this.setState({ loading: true });
      if (window.location.pathname === "/callback") {
        if (/access_token|id_token|error/.test(window.location.hash)) {
          await auth.handleAuthentication();
        }
      } else {
        auth.logIn();
      }
    }*/

    firebase.auth.onStatusChange((user) => {
      this.setState({ logged: user, loading: false });
    });

    //this.setState({ loading: false });
  }


  render() {
    if(this.state.loading) {
			return (<div style={{textAlign: 'center', height:"100vh"}}><Spin style={{position: 'absolute', top:'50%'}} size="large" /></div>);
    }

    /*if(!isAuthenticated) {
      //loginWithPopup({});
      //TODO: loginWithRedirect({});

      return (<div style={{textAlign: 'center', height:"100vh"}}><Spin style={{position: 'absolute', top:'50%'}} size="large" /></div>);
    }*/

    let navigation;

    if(!firebase.auth.getUser()) {
      navigation = NavigationExternal;
    } else {
      navigation = Navigation;
    }
    

		return (<Layout style={{height:"100vh"}}>
      <Router history={history}>
        <Route component={navigation} />
      </Router>
    </Layout>);
  }
}

export default App;
